import { request } from "../utils/request";
import { versionfn } from "../utils/version";
var version = versionfn.version;
//新增用户
export function getAddUser(data) {
  return request({
    url: `${version}/manage/users`,
    method: "post",
    data,
  });
}
//用户详情
export function userDetails(id) {
  return request({
    url: `${version}/manage/users/${id}`,
    method: "get",
  });
}
//禁用用户
export function userDisable(id) {
  return request({
    url: `${version}/manage/users/${id}/disable`,
    method: "put",
  });
}

//启用用户
export function userEnable(id) {
  return request({
    url: `${version}/manage/users/${id}/enable`,
    method: "put",
  });
}
//重置密码
export function resetPassword(id, data) {
  return request({
    url: `${version}/manage/users/${id}/resetPassword`,
    method: "post",
    data,
  });
}

//添加角色
export function getAddRoles(id, data) {
  return request({
    url: `${version}/manage/users/${id}/roles`,
    method: "post",
    data,
  });
}
//   删除角色
export function deleteRoles(id, data) {
  return request({
    url: `${version}/manage/users/${id}/roles`,
    method: "delete",
    data,
  });
}
//用户列表
export function userList(data) {
  return request({
    url: `${version}/manage/users/page`,
    method: "post",
    data,
  });
}
//用户详情(个人)
export function getUser() {
  return request({
    url: `${version}/user`,
    method: "get",
  });
}

// 修改用户信息（个人）
export function editUser(data) {
  return request({
    url: `${version}/user`,
    method: "put",
    data,
  });
}
// 修改密码（个人）
export function editUserPassword(data) {
  return request({
    url: `${version}/user/modifyPassword`,
    method: "put",
    data,
  });
}
