import Web3 from "web3";
import { Buffer } from "buffer-es6";
export default {
  //tron
  tronwebfn(address) {
    const TronWeb = require("tronweb");
    const tron_grid_api_key = "0f4c2d98-a688-4956-8fc3-2e0d3b9b914d"; //接后端
    const tronWeb = new TronWeb({
      fullHost: "https://api.trongrid.io",
      headers: { "TRON-PRO-API-KEY": tron_grid_api_key },
      privateKey: "",
    });
    let walletAddress = address;
    //abi为变量，可配置
    const minABI = [
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlackListed",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ];
    const tokenAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

    async function getBlackListed() {
      tronWeb.setAddress(tokenAddress);
      global.Buffer = Buffer;
      let contract = await tronWeb.contract(minABI, tokenAddress);
      const result = await contract.isBlackListed(walletAddress).call();
      //   console.log(`USDT block: ${result}`);
      return result;
    }

    return getBlackListed();
  },

  //bsc和eth
  ethorbscwebfn(address) {
    const web3 = new Web3(
      "https://ethereum-mainnet.s.chainbase.online/v1/2NdSmCEFvi9eONl5rTP5tH8nxMD"
    );
    console.log(web3);
    let walletAddress = address;
    //abi为变量，可配置
    const minABI = [
      {
        constant: true,
        inputs: [{ name: "", type: "address" }],
        name: "isBlacklisted",
        outputs: [{ name: "", type: "bool" }],
        type: "function",
      },
    ];
    const tokenAddress = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"; //合约固定
    const contract = new web3.eth.Contract(minABI, tokenAddress);
    async function getBlackListed() {
      const result = await contract.methods.isBlacklisted(walletAddress).call();
      // console.log(`USDT block: ${result}`);
      return result;
    }

    return getBlackListed();
  },
};
