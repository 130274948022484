import { request } from "../utils/request";
//登录
import { versionfn } from "../utils/version";
var version = versionfn.version;

//风险交易统计
export function riskData() {
  return request({
    url: `${version}/manage/riskStatistics/trade`,
    method: "post",
  });
}
