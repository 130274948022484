<template>
  <div class="contactkefu-style">
    <el-dialog
      class="dialog-style-public"
      :close-on-click-modal="false"
      title="联系客服"
      :visible.sync="addVisible"
      width="400px"
      :before-close="handleClose"
    >
      <!-- -->
      <div
        v-loading="!publicImg"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div>
          <p>如需联系客服，请扫一扫下方二维码添加客服。</p>
          <div class="contact-content">
            <div>
              <img :src="publicImg" alt="" />
              <p class="p-dingwei">微信公众号</p>
            </div>
            <div>
              <img :src="serviceImg" alt="" />
              <p class="p-dingwei">微信客服</p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import global from "@/components/Global.vue";
export default {
  props: ["iscontactkefu"],
  data() {
    return {
      addVisible: false,
      loginresource: [],
      publicImg: null,
      serviceImg: null,
    };
  },

  methods: {
    //关闭
    handleClose() {
      this.$emit("handleClose");
    },
  },
  created() {
    this.addVisible = this.iscontactkefu;
    global.getConfig();
  },
  mounted() {
    setTimeout(() => {
      global.getConfigdata.forEach((resitem) => {
        if (resitem.action == "LOGIN") {
          this.loginresource = resitem.configs;
        }
      });
      this.loginresource.forEach((item) => {
        //微信客服二维码地址
        if (item.configKey == "wechatCustomerQrCode") {
          this.serviceImg = item.configValue;
        }
        // 微信公众号二维码地址
        if (item.configKey == "wechatPublicQrCode") {
          this.publicImg = item.configValue;
        }
      });
    }, 1000);
  },
};
</script>
<style lang="less">
.contactkefu-style {
  cursor: pointer;
  box-sizing: border-box;
  .el-dialog {
    top: 16%;
    position: relative;
    width: 824px;
    // height: 600px;
    background: #0f212f;
    border: 1px solid rgba(64, 95, 104, 0.57);
  }
  .el-dialog__title {
    font-size: 20px;
    box-sizing: border-box;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.88);
    line-height: 60px;
  }
  .el-dialog__header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #233543;
  }
  .el-dialog__footer {
    padding: 30px 20px;
    border-top: 1px solid rgba(64, 95, 104, 0.57);
    display: flex;
    justify-content: center;
    .el-button:nth-child(1) {
      span {
        font-size: 20px;
      }
      color: #6ff0ea;

      // background: linear-gradient(
      //   180deg,
      //   rgba(112, 239, 232, 0.6) 0%,
      //   rgba(28, 47, 95) 100%
      // );
      // border-radius: 4px;
      // border: 0px;
      // border-image: linear-gradient(
      //   270deg,
      //   rgba(71, 126, 195, 0),
      //   rgba(112, 240, 234, 1),
      //   rgba(34, 72, 144, 0)
      // );
      margin-right: 40px;
    }
    .el-button:nth-child(2) {
      span {
        font-size: 20px;
      }

      // background: rgba(56, 78, 85, 0.5);
      // border-radius: 4px;
      // border: 0px solid;
    }
  }
  .contact-content {
    display: flex;
    justify-content: space-around;
    padding: 30px 0;
    img {
      width: 120px;
    }
  }
  .el-dialog__body {
    color: #809fb8;
  }
  .p-dingwei {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
