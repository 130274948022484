<template>
  <div class="code">
    <div id="captcha-element"></div>
    <el-button
      id="button"
      type="primary"
      @click="validateForm"
      v-show="deadlineboole"
      :disabled="disabled"
    >
      获取验证码
    </el-button>
    <el-button type="primary" v-show="!deadlineboole">
      <el-statistic
        ref="statistic"
        format="ss"
        @finish="hilarity"
        :value="deadline"
        time-indices
      >
      </el-statistic>
    </el-button>
  </div>
</template>
<script>
import { gentCaptcha } from "@/aips/login.js";
export default {
  props: ["registerForm"],
  data() {
    return {
      disabled: true,
      deadlineboole: true,
      deadline: null,
      initAliyunCaptcha: null,
    };
  },
  mounted() {
    window.initAliyunCaptcha({
      SceneId: "8uz8mpl9", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "1bh7z3", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "embed", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: "#button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 380,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      immediate: false, // 完成验证后，是否立即发送验证请求（调用captchaVerifyCallback函数）
    });
    // this.$nextTick(() => {
    //   this.setCaptchaWidth();
    // });
    // window.addEventListener("resize", this.setCaptchaWidth);
  },
  watch: {
    registerForm: {
      deep: true,
      handler(newValue) {
        console.log("打印我");
        console.log(newValue);
        if (newValue.account !== "" && newValue.phone !== "") {
          this.disabled = false;
          console.log("进来");
          console.log(this.disabled);
        }
      },
    },
  },
  methods: {
    // setCaptchaWidth() {
    //   const aliyunId = document.querySelector("#aliyunCaptcha-sliding-left");
    //   if (aliyunId) {
    //   }
    // },
    hilarity() {
      this.deadlineboole = true;
    },
    validateForm() {
      console.log(23423423);
      console.log(this.registerForm);
      document.getElementById("button").click();
    },
    //  获取手机验证码
    getInstance(instance) {
      window.captcha = instance;
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      if (this.registerForm.account == "") {
        this.$message({
          message: "请输入账号",
          type: "warning",
          duration: 1000,
        });
        return;
      } else if (this.registerForm.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
          duration: 1000,
        });
        return;
      } else {
        console.log("我是滑动条");
        let data = {
          account: this.registerForm.account,
          captchaVerifyParam,
          phone: this.registerForm.phone,
        };
        // 1.向后端发起业务请求，获取验证码验证结果和业务结果
        const response = await gentCaptcha(data);
        console.log("我是响应");
        console.log(response);
        let result = false;
        if (response.code == 200) {
          this.deadline = Date.now() + 1000 * 60;
          this.deadlineboole = false;
          result = true;
        } else {
          return;
        }
        const verifyResult = {
          captchaResult: result, // 验证码校验结果，必须为 布尔值
          bizResult: result, // 业务校验结果，必须为 布尔值
        };
        return verifyResult;
      }
    },

    onBizResultCallback(bizResult) {
      if (bizResult) {
        let data = false;
        this.$emit("getdata", data);
        this.$message({
          message: "手机验证码已发送，请注意查收",
          type: "success",
          duration: 1000,
        });
      } else {
        this.$message({
          message: "验证码发送失败，请重试",
          type: "warning",
          duration: 1000,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
#captcha-element {
  width: 400px;
}
.code {
  ::v-deep .el-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 130px;
    height: 56px;
    box-sizing: border-box;
    border: 0;
    z-index: 99;
    background: #00e1eb;
    font-size: 18px;
    color: #001a2b;
  }
}
::v-deep .aliyunCaptcha-show {
  // margin-top: 20px;
}
::v-deep #aliyunCaptcha-sliding-body {
  width: 400px !important;
}
::v-deep #aliyunCaptcha-sliding-left {
  width: 100% !important;
}
// ::v-deep .ok {
//   left: 80% !important;
// }
// @media screen and (min-width: 1101px) and (max-width: 1600px) {
//   ::v-deep .ok {
//     left: 84% !important;
//   }
// }
// @media screen and (min-width: 1601px) and (max-width: 1920px) {
//   ::v-deep .ok {
//     left: 86.5% !important;
//   }
// }
// @media screen and (min-width: 1920px) and (max-width: 2700px) {
//   ::v-deep .ok {
//     left: 89.5% !important;
//   }
// }
</style>
