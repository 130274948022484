import { request } from "../utils/request";
import { versionfn } from "../utils/version";
var version = versionfn.version;
//地址分析聚合边分页列表
export function AnalysisDataPageTask(data) {
  return request({
    url: `${version}/address/analysis/findAddressAnalysisDataPageTask`,
    method: "post",
    data,
  });
}
//地址分析-开始分析
export function Analysisvertex(data) {
  return request({
    url: `${version}/address/analysis/vertex`,
    method: "post",
    data,
  });
}
//地址分析扩展数据节点
export function dataVertex(data) {
  return request({
    url: `${version}/address/analysis/extend/dataVertex`,
    method: "post",
    data,
  });
}
//地址分析扩展数据节点
export function usdtCounterParty(data) {
  return request({
    url: `${version}/analysis/address/usdtCounterParty`,
    method: "post",
    data,
  });
}
// 地址多重搜索，支持多链
export function multiSearch(data) {
  return request({
    url: `${version}/address/analysis/multiSearch`,
    method: "post",
    data,
  });
}
// swap交易点边结构转换
export function convertSwapTransactionToGraph(data) {
  return request({
    url: `${version}/address/analysis/convertSwapTransactionToGraph`,
    method: "post",
    data,
    timeout: 40000,
  });
}
// swap交易查询
export function swapTransactions(data) {
  return request({
    url: `${version}/address/analysis/swapTransactions`,
    method: "post",
    data,
    timeout: 40000,
  });
}

//新版数据多重查询(地址 / 交易hash);
export function newdataMultiSearch(data) {
  return request({
    url: `${version}/data/analysis/dataMultiSearch`,
    method: "post",
    data,
  });
}
//数据搜索类型
export function newdatatype(data) {
  return request({
    url: `${version}/data/analysis/type`,
    method: "post",
    data,
  });
}
