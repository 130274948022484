<template>
  <div class="register-main">
    <h2>用户注册</h2>
    <div class="content-title" @click="jumpLogin">
      已有账户？<span>登录</span>
    </div>
    <el-form
      :model="registerForm"
      label-position="left"
      :rules="registerrules"
      ref="registerForm"
      class="ruledfrom-style"
    >
      <el-form-item prop="account">
        <el-input
          v-model="registerForm.account"
          autocomplete="off"
          placeholder="请输入账号（必填）"
        ></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <el-input
          type="phone"
          v-model="registerForm.phone"
          autocomplete="off"
          placeholder="请输入手机号（必填）"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item >
        <phonecode @getdata="getdata" :registerForm="registerForm"></phonecode>
      </el-form-item> -->
      <el-form-item prop="captcha" class="code-style">
        <!-- <phonecode @getdata="getdata" :registerForm="registerForm"></phonecode> -->
        <el-input
          v-model="registerForm.captcha"
          autocomplete="off"
          placeholder="请输入验证码"
        ></el-input>
        <el-button
          id="button"
          type="primary"
          @click="validateForm"
          v-if="deadlineboole"
        >
          获取验证码
        </el-button>
        <el-button class="numBtn" type="primary" v-else>
          <el-statistic
            ref="statistic"
            format="ss"
            @finish="hilarity"
            :value="deadline"
            time-indices
          >
          </el-statistic>
        </el-button>
      </el-form-item>
      <el-form-item prop="newpassword">
        <el-input
          type="password"
          v-model="registerForm.newpassword"
          autocomplete="off"
          placeholder="8-16个字符，至少1个字母，1个数字和1个特殊字符（必填）"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item prop="surepassword">
        <el-input
          type="password"
          v-model="registerForm.surepassword"
          autocomplete="off"
          placeholder="确认密码（必填）"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item class="register-style">
        <!-- <div class="register-set">
          <span @click="jumpLogin">使用已有账号登录</span>
        </div> -->
        <el-button @click="registersubmit('registerForm')">注册</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      class="dialog-style-public-state"
      :close-on-click-modal="false"
      :visible.sync="registersuccess"
      :before-close="handleClosesuccess"
      width="400px"
    >
      <div
        class="contentdiag"
        v-loading="!serviceImg"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <img src="@/assets/image/newadjustment/status.png" alt="" />
        <p class="remarkdialog">注册成功！</p>
        <p class="nextremarkdialog">添加企业微信申请更多服务！</p>
        <img style="width: 200px" :src="serviceImg" alt="" />
      </div>

      <div class="dialog-btn">
        <el-button type="primary" @click="registersuccess = false"
          >关闭弹窗</el-button
        >
        <el-button @click="jumpLogin" type="success">返回登录</el-button>
      </div>
      <!-- <el-result
        icon="success"
        title="注册成功"
        subTitle="添加企业微信申请更多服务"
        class="resultbox"
      >
        <template slot="extra">
          <div class="retrunlogin">
            <img src="@/assets/image/login/weixin.png" alt="" />
            <el-button type="success" @click="jumpLogin">返回登录</el-button>
          </div>
        </template>
      </el-result> -->
    </el-dialog>
  </div>
</template>
<script>
import phonecode from "./phonecode.vue";
import { gentCaptcha, Register } from "@/aips/login";
import global from "@/components/Global.vue";
export default {
  prop: ["olduserBool"],
  components: {
    phonecode,
  },
  data() {
    return {
      loginresource: [],
      serviceImg: null,
      deadline: null,
      deadlineboole: true,
      phonecodeboole: true,
      registerForm: {
        account: "",
        phone: "",
        captcha: "",
        newpassword: "",
      },
      registersuccess: false,
      registerrules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        captcha: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 4,
            max: 4,
            message: "验证码格式不对",
            trigger: "blur",
          },
        ],
        newpassword: [
          {
            required: true,
            message:
              "8-16个字符，至少1个字母，1个数字和1个特殊字符[$@$!%*#?&](必填)",
            trigger: "blur",
          },
          {
            min: 8,
            max: 16,
            message: "8-16个字符，至少1个字母，1个数字和1个特殊字符[$@$!%*#?&]",
            trigger: "blur",
          },
          {
            pattern:
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&.])[A-Za-z\d$@$!%*#?&.]{8,16}$/,
            message: "密码必须包含至少1个字母、1个数字和1个特殊字符[.$@$!%*#?&]",
            trigger: "blur",
          },
        ],
        surepassword: [
          { required: true, validator: this.confirmPassFn, trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    hilarity() {
      this.deadlineboole = true;
    },
    // 获取验证码
    async validateForm() {
      console.log("获取验证码");
      let data = {
        account: this.registerForm.account,
        phone: this.registerForm.phone,
      };
      // 1.向后端发起业务请求，获取验证码验证结果和业务结果
      const response = await gentCaptcha(data);
      console.log("我是响应");
      console.log(response);
      // let result = false;
      if (response.code == 200) {
        this.deadline = Date.now() + 1000 * 60;
        this.deadlineboole = false;
        // result = true;
      } else {
        return;
      }
    },
    //  确认两次密码
    confirmPassFn(rule, value, callback) {
      console.log(value);
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerForm.newpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    },
    getdata(data) {
      console.log("我是data");
      console.log(data);
      this.phonecodeboole = false;
    },
    handleClosesuccess() {
      this.registersuccess = false;
      let data = 0;
      this.$emit("changepage", data);
    },
    jumpLogin() {
      let data = 0;
      this.$emit("changepage", data);
    },
    // 注册
    registersubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.deadlineboole) {
            this.$message({
              message: "请先获取验证码或验证码已过期！",
              type: "warning",
              duration: 1000,
            });
            return;
          }
          let data = {
            password: this.registerForm.newpassword,
            phone: this.registerForm.phone,
            captcha: this.registerForm.captcha,
            account: this.registerForm.account,
          };
          Register(data)
            .then((res) => {
              if (res.code == 200) {
                this.registersuccess = true;
              } else {
                // this.$message({
                //   message: "注册失败请重试！",
                //   type: "warning",
                //   duration: 1000,
                // });
              }
            })
            .catch((err) => {
              this.$message.error("注册失败请重试");
              this.registerForm.newpassword = "";
              this.registerForm.phone = "";
              this.registerForm.captcha = "";
              this.registerForm.account = "";
              this.phonecodeboole = true;
            });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    global.getConfig();
  },
  mounted() {
    setTimeout(() => {
      global.getConfigdata.forEach((resitem) => {
        if (resitem.action == "LOGIN") {
          this.loginresource = resitem.configs;
        }
      });
      this.loginresource.forEach((item) => {
        //微信客服二维码地址
        if (item.configKey == "wechatCustomerQrCode") {
          this.serviceImg = item.configValue;
        }
      });
    }, 1000);
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/newlogin.less";
</style>
<style lang="less">
@import "@/assets/css/publicStyle/dialog.less";
</style>
