import { request } from "../utils/request";
//登录
import { versionfn } from "../utils/version";
var version = versionfn.version;
//获取所有画布信息
export function getCanvas(data) {
  return request({
    url: `${version}/manage/canvases/page`,
    method: "post",
    data,
  });
}
//查询用户指定canvasId的完整画布数据
export function getCanvasId(id, brarchId) {
  return request({
    url: `${version}/manage/canvases/${id}/${brarchId}`,
    method: "get",
  });
}
//新增画布
export function getAddCanvas(data) {
  return request({
    url: `${version}/manage/canvases`,
    method: "post",
    data,
  });
}
// 修改画布
export function editCanvas(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}`,
    method: "put",
    data,
  });
}
//   删除画布
export function deleteCanvas(id) {
  return request({
    url: `${version}/manage/canvases/${id}`,
    method: "delete",
  });
}

// 获取指定交易hash的交易信息
export function txhash(data) {
  return request({
    url: `${version}/third/query/txhash`,
    method: "post",
    data,
  });
}
// 获取一页指定地址的交易信息
export function address(data) {
  return request({
    url: `${version}/third/page`,
    method: "post",
    data,
  });
}
//编辑画布历史记录
export function canvasesHistoryrecord(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}/historyrecord/del`,
    method: "post",
    data,
  });
}
//画布历史详情-新版
export function HistoryrecordDetail(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}/historyrecord/detail`,
    method: "post",
    data,
  });
}
//新增画布分支
export function canvasesBranchAdd(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}/branch/add`,
    method: "post",
    data,
  });
}
//子级画布历史列表-新版
export function historyrecordsForparent(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}/historyrecords/forparent/page`,
    method: "post",
    data,
  });
}

//画布历史列表
export function Historyrecords(id, data) {
  return request({
    url: `${version}/manage/canvases/${id}/historyrecords/page`,
    method: "post",
    data,
  });
}
//画布配置源编辑
export function configsource(data) {
  return request({
    url: `${version}/manage/canvases/configsource`,
    method: "post",
    data,
  });
}
//画布数据全局刷新
export function globalrefresh(data) {
  return request({
    url: `${version}/manage/canvases/globalrefresh`,
    method: "post",
    data,
  });
}
//查询交易对手信息
export function counterParty(data) {
  return request({
    url: `${version}/analysis/vertices/counterParty`,
    method: "post",
    data,
    timeout: 60000,
  });
}
export function addresscounterParty(data) {
  return request({
    url: `${version}/analysis/address/counterParty`,
    method: "post",
    data,
    timeout: 60000,
  });
}
// 获取链
export function BlockchainOptions() {
  return request({
    url: `${version}/analysis/blockchain/options`,
    method: "get",
  });
}
//模糊查询货币信息
export function currenciesMatch(param) {
  return request({
    url: `${version}/analysis/blockchain/currencies/match`,
    method: "get",
    params: { keyword: param },
  });
}
//模糊查询货币信息
export function analysiscurrenciesMatch(param) {
  return request({
    url: `${version}/analysis/currencies/match`,
    method: "get",
    params: { keyword: param },
  });
}
// 查询案件所有画布信息
export function caseCanvasList(id) {
  return request({
    url: `${version}/manage/cases/${id}/canvas/list`,
    method: "post",
  });
}
// 查询画布类型
export function canvastypeoptions() {
  return request({
    url: `${version}/manage/canvas/type/options`,
    method: "get",
  });
}

// 查询画布类型
// export function canvastypeoptions() {
//   return request({
//     url: `${version}/manage/canvas/type/options`,
//     method: "get",
//   });
// }
//   删除通用画布
export function deleteGenerCanvas(id) {
  return request({
    url: `${version}/manage/generic/canvas/delete/${id}`,
    method: "delete",
  });
}
// 查询通用画布
export function selectgeneralcanvas(id) {
  return request({
    url: `${version}/manage/generic/canvas/query/${id}`,
    method: "get",
  });
}
//更新通用画布
export function updategeneralcanvas(id,data) {
  return request({
    url: `${version}/manage/generic/canvas/update/${id}`,
    method: "put",
    data
  });
}