import {axiosInstance1} from "@/utils/request";
import { versiongeneral } from "@/utils/version";
var version = versiongeneral.version;
// var request=axiosInstance1
//获取编辑地址监控详情信息
// export function Monitoringdetails(id) {
//     return axiosInstance1({
//       url: `${version}/address/monitoring/${id}`,
//       method: "get",
//     });
//   }
  
// 画布配置查询接口
export function canvasconfig(data) {
    return axiosInstance1({
      url: `${version}/manage/canvas/config/query`,
      method: "post",
      data,
    }).then(response => response.data);
  }
  //搜索点
export function canvassearch(data) {
    return axiosInstance1({
      url: `${version}/manage/canvas/analysis/search`,
      method: "post",
      data,
    }).then(response => response.data);
  }
    //拓展点
export function canvasextend(data) {
    return axiosInstance1({
      url: `${version}/manage/canvas/analysis/vertex/extend`,
      method: "post",
      data,
    }).then(response => response.data);
  }
      //点详情
export function vertexdetail(data) {
    return axiosInstance1({
      url: `${version}/manage/canvas/analysis/vertex/detail`,
      method: "post",
      data,
    }).then(response => response.data);
  }

        //边详情
export function edgedetail(data) {
    return axiosInstance1({
      url: `${version}/manage/canvas/analysis/edge/page`,
      method: "post",
      data,
    }).then(response => response.data);
  }

  //地址点交易对手(USDT)
  export function opponentusdt(data) {
    return axiosInstance1({
      url: `${version}/manage/vertex/address/opponent/usdt`,
      method: "post",
      data,
    }).then(response => response.data);
  }

    //地址点交易对手(USDT)
    export function otherInfoimTokenOperation(data) {
        return axiosInstance1({
          url: `${version}/manage/vertex/address/otherInfo/imTokenOperation`,
          method: "post",
          data,
        }).then(response => response.data);
      }
          //地址点交易对手(USDT)
    export function otherInfotokenPocketLogin(data) {
        return axiosInstance1({
          url: `${version}/manage/vertex/address/otherInfo/tokenPocketLogin`,
          method: "post",
          data,
        }).then(response => response.data);
      }
                //数据多重查询(地址/交易hash)
    export function dataMultiSearch(data) {
      return axiosInstance1({
        url: `${version}/manage/data/analysis/dataMultiSearch`,
        method: "post",
        data,
      }).then(response => response.data);
    }