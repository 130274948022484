<template>
  <div class="otherfooter">
    <div class="leftimg">
      <img v-if="logoimgurl" :src="logoimgurl" alt="logo" />
      <el-skeleton style="width: 240px" v-else>
        <template slot="template">
          <div style="display: flex; align-items: center">
            <el-skeleton-item
              variant="image"
              style="width: 30px; height: 30px; background: #1c2634"
            />

            <el-skeleton-item
              variant="p"
              style="
                width: 144px;
                margin-left: 6px;
                height: 20px;
                background: #1c2634;
              "
            />
          </div>
        </template>
      </el-skeleton>
    </div>
    <div class="righttext">
      <span>成都北鸣电子科技有限公司</span>
      <!-- <div></div>
      <span>备案编号：蜀ICP备2023003901号.</span> -->
    </div>
  </div>
</template>
<script>
import global from "@/components/Global.vue";
export default {
  data() {
    return {
      logoimgurl: null,
    };
  },
  mounted() {
    // document.addEventListener("click", (e) => {
    //   const personage = document.getElementById("personage");
    //   if (personage) {
    //     if (!personage.contains(e.target)) {
    //       this.ispersonage = false;
    //     }
    //   }
    // });
    let userroles = JSON.parse(localStorage.roles);
    console.log(userroles);
    userroles.sort(function (a, b) {
      return b.weight - a.weight;
    });
    this.userroles = userroles[0].value;
    if (sessionStorage.logoimgurl) {
      let logoimg = JSON.parse(sessionStorage.logoimgurl);
      this.logoimgurl = logoimg;
    } else {
      this.getGlobalInfo();
    }
  },
  methods: {
    getGlobalInfo() {
      setTimeout(() => {
        console.log(global.getConfigdata);
        global.getConfigdata.forEach((resitem) => {
          if (resitem.action == "LOGIN") {
            this.loginresource = resitem.configs;
          }
        });
        this.loginresource.forEach((item) => {
          if (item.configKey == "logo") {
            this.logoimgurl = item.configValue;
            sessionStorage.logoimgurl = JSON.stringify(this.logoimgurl);
          }
        });
      }, 1500);
    },
  },
};
</script>
<style lang="less" scoped>
.otherfooter {
  display: flex;
  padding: 29px 40px 29.53px 40px;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #070d15;
  .leftimg {
    display: flex;
    align-items: center;
    img {
      height: 31.94px;
    }
  }
  .righttext {
    color: var(--web-color-text-tertiary, #a2a9b6);
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    div {
      width: 1px;
      height: 14px;
      flex-shrink: 0;
      background: var(--web-color-icon-quarternary, #bac0ca);
      margin: 0 12px;
    }
  }
}
</style>
