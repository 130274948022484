<template>
  <div class="selectbox">
    <div class="addressnum">地址{{ addresslength }}</div>
    <div class="addresslist" v-for="(item, index) in addressarray" :key="index">
      <div class="addresstext" @click="addressdetails(item)">
        <div class="leftimg">
          <img :src="item.tagIconUrl" alt="标签" />
        </div>
        <div class="righttext">
          <div class="adddressstyle">
            {{ item.address }}
          </div>
          <span class="chainstyle">
            <img :src="item.blockchain.label.iconUrl" alt="链路" />
            <span>{{ item.blockchain.label.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["addressarray", "addresslength", "selectwidth"],
  data() {
    return {};
  },
  methods: {
    addressdetails(v) {
      this.$emit("handlejumpdetails", v);
    },
  },
  mounted() {
    console.log("我是宽度");
    console.log(this.selectwidth);
    console.log(this.addressarray);
    let selectbox = document.querySelector(".selectbox");
    console.log(selectbox);
    selectbox.style.width = `${this.selectwidth}px`;
    // selectbox.style.width = "720px";
  },
};
</script>
<style lang="less" scoped>
.selectbox {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 3%);
  border-radius: 24px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.08);
  background: #fff;
  display: inline-flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  z-index: 2;
  // width: 720px;
  .addressnum {
    color: var(--Gray-3, #828282);
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 20px;
  }
  .addresslist {
    width: 100%;
    .addresstext {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      .leftimg {
        height: 46px;
        img {
          height: 100%;
          margin-right: 12px;
        }
      }
      .righttext {
        .adddressstyle {
          color: var(--Gray-1, #333);
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .chainstyle {
          display: flex;
          align-items: center;
          padding: 2px 4px;
          gap: 4px;
          border-radius: 2px;
          box-sizing: border-box;
          border: 1px solid #e0e0e0;
          background: #fff;
          width: 90px;
          height: 22px;

          img {
            height: 16px;
            // margin: 0 4px;
          }
          span {
            color: #4f4f4f;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
          }
        }
      }
    }
    .addresstext:hover {
      background: #f2f2f2;
    }
  }
}
</style>
