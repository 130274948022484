export default {
  numberExchange(value) {
    // console.log("我是value");
    // console.log(value);
    if (!value) return 0;
    // if (value == "--") return "--";
    // 获取整数部分
    const intPart = Math.trunc(value);
    // 整数部分处理，增加,
    const intPartFormat = intPart
      .toString()
      .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    // 预定义小数部分
    let floatPart = "";
    // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split(".");
    if (valueArray.length === 2) {
      // 有小数部分
      floatPart = valueArray[1].toString(); // 取得小数部分
      // 保留三位小数并四舍五入
      if (floatPart.length > 3) {

        // const roundedValue =
        //   Math.round(parseFloat("0." + floatPart) * 1000) / 1000;
        // floatPart = roundedValue.toString().split(".")[1] || "000"; // 保证小数部分有三位
        floatPart = floatPart.substring(0, 3);
      }
  //     else{
  //       // 如果长度不足三位，进行补全
  // floatPart = floatPart.padEnd(3, '0');
  //     }
      return intPartFormat + "." + floatPart;
    }
    return intPartFormat + floatPart;
  },
};
