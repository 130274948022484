import { request } from "../utils/request";
//首页
import { versionfn } from "../utils/version";
var version = versionfn.version;

//全国案件判例
export function caseStatistics() {
  return request({
    url: `${version}/index/caseStatistics`,
    method: "get",
  });
}
//平台数据统计
export function platformStatistic() {
  return request({
    url: `${version}/index/platformStatistic`,
    method: "get",
  });
}
//风险交易预警
export function riskTrade() {
  return request({
    url: `${version}/index/riskTrade`,
    method: "get",
  });
}
//恶意地址转账趋势图
export function spiteTrendgram() {
  return request({
    url: `${version}/index/spiteTrendgram`,
    method: "get",
  });
}
