<template>
  <div class="pageContent">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  provide() {
    return {
      reload: this.reload, //把方法传递给下级组件
    };
  },
};
</script>

<style lang="less" scoped>
.content {
  // height: 90vh;
  width: 100%;
}
</style>
