import { request } from "../utils/request";
import { versionfn } from "../utils/version";

var version = versionfn.version;

//查询区块链行业知识详情
export function blockchainKnowledgeDetail(id) {
  return request({
    url: `${version}/help/expertService/blockchainKnowledge/detail/${id}`,
    method: "get",
  });
}
//查询区块链案件侦查实录详情
export function expertServiceDetail(id) {
    return request({
      url: `${version}/help/expertService/blockchainCaseInvestigation/detail/${id}`,
      method: "get",
    });
  }
  //分页查询区块链案件侦查实录信息
export function expertServicePage(data) {
    return request({
      url: `${version}/help/expertService/blockchainCaseInvestigation/page`,
      method: "post",
      data,
    });
  }
    //分页查询区块链行业知识信息
export function blockchainKnowledgePage(data) {
    return request({
      url: `${version}/help/expertService/blockchainKnowledge/page`,
      method: "post",
      data,
    });
  }
  //菜单信息
export function expertServiceMenu() {
    return request({
      url: `${version}/help/expertService/menu`,
      method: "get",
    });
  }
    //查询政策法规详情
export function policyRegulationDetail(id) {
    return request({
      url: `${version}/help/expertService/policyRegulation/detail/${id}`,
      method: "get",
    });
  }
      //分页查询政策法规信息
export function policyRegulationPage(data) {
    return request({
      url: `${version}/help/expertService/policyRegulation/page`,
      method: "post",
      data,
    });
  }