<template >
    <div class="countpage-style">
        <h1>平台数据统计</h1>
          <div class="right-content content-margin">
            <span class="content-title">涉案金额</span>
            <span class="title-info">
            <span>

              <span class="number-style">￥</span>
          <span class="number-style" ref="numberPrice">{{
            relatedCaseAmount
          }}</span>
          <span class="span-style">万</span>
            </span>
            </span>
          </div>
          <div class="right-content content-margin1">
            <span class="content-title">协助案件</span>
            <span class="title-info">
              <span>

                <span class="number-style" ref="assistanceEvents">{{
             relatedCaseQuantity
            }}</span>
            <span class="span-style">件</span>
              </span>
              </span>
          </div>
    </div>
</template>
<script>
import { CountUp } from "countup.js";
export default {
    props: ["platformData"],
    data() {
    return {
      relatedCaseAmount: 0,
      relatedCaseQuantity: 0,
    };
  },
  methods: {},
  // 监听父组件传过来的数据
  watch: {
    platformData: {
      handler(newVal, oldVal) {
        this.platformData = newVal;
      },
      // 开启深度侦听
      deep: true,
      // 立即监听
      immediate: true,
    },
  },

  mounted() {
    const options = {
      // 小数点位
      decimalPlaces: 0,
      // 持续时间
      duration: 2,
    };
    new CountUp(
      this.$refs.numberPrice,
      this.relatedCaseAmount,
      options
    ).start();
    new CountUp(
      this.$refs.assistanceEvents,
      this.relatedCaseQuantity,
      options
    ).start();
  },
  created() {
    this.relatedCaseAmount = this.platformData.relatedCaseAmount;
    this.relatedCaseQuantity = this.platformData.relatedCaseQuantity;
    // //console.log("我是平台数据统计的created");
    // //console.log(this.relatedCaseAmount);
    // //console.log(this.relatedCaseQuantity);
    // //console.log(111);
  },
}
</script>
<style lang="less" scoped>
    .countpage-style{
      
      // display: flex;
      // justify-content: space-between;
      width: 480px;
      // flex-direction: column;
      
      >h1{
        color: var(--web-color-text-primary, #F2F4F6);
font-family: "PingFang SC";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
      }
      .right-content{
     
        .content-title{
        

          color: var(--web-color-text-secondary, #87909F);
font-family: "PingFang SC";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
        }
        .title-info{
          margin-top: 16px;
          display: inline-block;
          display: flex;
// width: 450px;
// max-width: 500px;
height: 168px;
padding: 0px 24px;
flex-direction: column;
justify-content: center;
box-sizing: border-box;
// align-items: center;
// align-items: flex-start;
// gap: 12px;
border-radius: 8px;
background: #0F1721;
.span-style {
  color: var(--web-color-text-primary, #F2F4F6);
  font-family: "San Francisco Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.number-style {
  color: var(--web-color-text-primary, #F2F4F6);
  font-family: "San Francisco Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 10px;
}
        }
      }
      .content-margin{
        margin-top: 48px;
      }
      .content-margin1{
        margin-top: 12px;
      }
  
    }
</style>