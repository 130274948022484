<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>
<script>
import global from "@/components/Global.vue";
export default {
  created() {
    global.getConfig();
  },
};
</script>

<style>
body {
  /* height: 100%; */
  /* background: #09081d; */
}
</style>
