import { request } from "../utils/request";
//登录
import { versionfn } from "../utils/version";
var version = versionfn.version;
//新增用户反馈
export function globalResource(data) {
  return request({
    url: `${version}/resourceconfig/findConfigBySite`,
    method: "post",
    data,
  });
}

// 上报接口
export function report(data) {
  return request({
    url: `/system/report`,
    method: "post",
    data,
  });
}
