import { request } from "../utils/request";
import { versionfn } from "../utils/version";
var version = versionfn.version;
//查询用户引导数据信息
export function getUserGuideData(data) {
  return request({
    url: `${version}/getUserGuideData`,
    method: "post",
    data,
  });
}
//新增用户引导数据信息;
export function addUserGuideData(data) {
  return request({
    url: `${version}/addUserGuideData`,
    method: "post",
    data,
  });
}
