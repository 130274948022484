import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/newtheme/newhome/homeview.vue";
// import Login from "@/views/Login/Login.vue";
import Login from "@/views/newtheme/newLogin/Login.vue";
import Analysis from "@/views/newtheme/newhome/homepage.vue";
import { getUserGuideData } from "@/aips/beginnerGuidance";
// import CustomDialog from '../components/MessageBox.vue'
Vue.use(VueRouter);

// Vue.component('custom-dialog', CustomDialog);

//异步
const Adjustment = () => import("@/views/newtheme/Adjustment/Adjustment.vue");
// const Adjustment = () => import("@/views/Adjustment/Adjustment.vue");
// const Datacase = () => import("@/views/Datacase/DataCase.vue");
const Datacase = () => import("@/views/newtheme/newDatacase/DataCase.vue");
const Help = () => import("../views/newtheme/newHelp/Help.vue");
const Permissions = () => import("../views/Permissions/Permissions.vue");
const canvasshare = () => import("@/views/Analysis/canvas/share/canvasShare");
const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "",
    component: Layout,
    meta: {
      title: "首页",
      showHeader: true,
      showfooter: true,
    },
    children: [
      {
        path: "/home",
        name: "Analysis",
        component: Analysis,
        meta: {
          title: "首页",
          showHeader: true,
          showfooter: true,
        },
        children: [
          {
            path: "/home/addressMonitor",
            name: "AddressMonitor",
            component: () =>
              import("@/views/newtheme/newMonitor/monitorpage.vue"),
            meta: {
              title1: "研判分析",
              title: "地址监控",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/home/monitordetail",
            name: "Monitordetail",
            component: () =>
              import("@/views/newtheme/newMonitor/monitordetail.vue"),
            meta: {
              title1: "地址监控",
              title: "监控详情",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/home/transactiondetails/:id",
            name: "transactiondetails",
            component: () =>
              import("@/views/newtheme/details/transactiondetails.vue"),
            meta: {
              title1: "研判分析",
              title: "交易详情",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/home/addressdetail/:id",
            name: "addressdetail",
            component: () =>
              import("@/views/newtheme/details/addressdetail.vue"),
            meta: {
              title1: "研判分析",
              title: "地址详情",
              showHeader: true,
              showfooter: true,
            },
          },
          // 案件分析
          {
            path: "/home/caseAnalysis",
            name: "CaseAnalysis",
            component: () =>
              import("@/views/newtheme/newcase/Caseanalysis.vue"),
            meta: {
              title1: "研判分析",
              title: "案件分析",
              showHeader: true,
              showfooter: true,
            },
            children: [
              {
                path: "/caseAnalysis/canvaslist",
                name: "Canvaslist",
                component: () =>
                  import("@/views/newtheme/newcase/Canvascontent"),
                meta: {
                  title1: "案件分析",
                  title: "案件画布列表",
                  showHeader: true,
                  showfooter: true,
                },
                children: [
                  // {
                  //   path: "/canvaslist/canvas",
                  //   name: "Canvas",
                  //   component: () => import("@/views/Analysis/canvas/Canvas"),
                  //   meta: {
                  //     title1: "案件画布列表",
                  //     title: "画布",
                  //     showHeader: true,
                  // showfooter: true,
                  //   },
                  // },
                  {
                    path: "/canvaslist/newcanvas",
                    name: "Canvas",
                    component: () =>
                      import("@/views/Analysis/canvas/newCanvas"),
                    meta: {
                      title1: "案件画布列表",
                      title: "画布",
                      showHeader: true,
                      showfooter: false,
                    },
                  },
                  {
                    path: "/canvaslist/historylist",
                    name: "Historylist",
                    component: () =>
                      import("@/views/newtheme/historylist/historylist.vue"),

                    meta: {
                      title1: "案件画布列表",
                      title: "历史列表",
                      showHeader: true,
                      showfooter: true,
                    },
                  },
                  {
                    path: "/historylist/historydetail",
                    name: "Historydetail",
                    component: () =>
                      import("@/views/Analysis/canvas/history/historydetail"),
                    meta: {
                      title1: "案件画布列表",
                      title: "历史详情",
                      showHeader: true,
                      showfooter: true,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/home/addressanalysis",
            name: "Addressanalysis",
            component: () =>
              import("@/views/Analysis/addressAnalysis/Addressanalysis"),
            meta: {
              title1: "研判分析",
              title: "地址分析",
              showHeader: true,
              showfooter: true,
            },
            children: [
              {
                path: "/home/balanceselect",
                name: "Balanceselect",
                component: () =>
                  import("@/views/newtheme/newbalance/balancelist.vue"),
                meta: {
                  title1: "地址分析",
                  title: "余额查询",
                  showHeader: true,
                  showfooter: true,
                },
              },
              {
                path: "/home/labelselect",
                name: "Labelselect",
                component: () =>
                  import("@/views/newtheme/newlabel/labeldetail.vue"),
                // import(
                //   "@/views/Analysis/addressAnalysis/labelmanagement/Addresslabelquery.vue"
                // ),
                meta: {
                  title1: "地址分析",
                  title: "标签查询",
                  showHeader: true,
                  showfooter: true,
                },
              },
            ],
          },
          {
            path: "/home/modelanalysis",
            name: "Modelanalysis",
            component: () =>
              import("@/views/newtheme/newmodel/modelanalysis.vue"),
            meta: {
              title1: "研判分析",
              title: "模型分析",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/home/character",
            name: "Character",
            component: () =>
              import("@/views/Analysis/characteranalysis/Characteranalysis"),
            meta: {
              title1: "研判分析",
              title: "人物分析",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/home/userdetailspage/:id",
            name: "userdetailspage",
            component: () =>
              // import("@/views/newtheme/details/addressdetail.vue"),
              import(
                "@/views/newtheme/details/userDetails/userdetailsmain.vue"
              ),
            meta: {
              title1: "研判分析",
              title: "用户详情",
              showHeader: true,
              showfooter: true,
            },
          },
        ],
      },
      {
        path: "/adjustment",
        name: "Adjustment",
        component: Adjustment,
        meta: {
          title: "调证管理",
          showHeader: true,
          showfooter: true,
        },
        children: [
          {
            path: "/adjustment/advise",
            name: "Advise",
            component: () =>
              import(
                "@/views/newtheme/Adjustment/recommendation/squareadvise.vue"
                // "@/views/Adjustment/squareadvise.vue"
              ),
            meta: {
              title1: "调证管理",
              title: "调证建议",
              showHeader: true,
              showfooter: true,
            },
            hidden: true,
            children: [
              {
                path: "/advise/surveydocument",
                name: "surveydocument",
                component: () =>
                  import(
                    "@/views/newtheme/Adjustment/recommendation/Surveydocument.vue"
                    // "@/views/Adjustment/Surveydocument.vue"
                  ),
                meta: {
                  title1: "调证建议",
                  title: "调证文书",
                  showHeader: true,
                  showfooter: true,
                },
              },
            ],
          },

          {
            path: "/adjustment/querysuspects-list",
            name: "querysuspects-list",
            component: () =>
              import(
                "@/views/newtheme/Adjustment/UserList/querysuspectser.vue"
                // "@/views/Adjustment/querysuspectser.vue"
              ),
            meta: {
              title1: "调证管理",
              title: "调证用户",
              showHeader: true,
              showfooter: true,
            },
            hidden: true,
            children: [
              {
                path: "/querysuspects-list/surveyreport",
                name: "surveyReport",
                component: () =>
                  import(
                    "@/views/newtheme/Adjustment/UserList/surveyReport.vue"
                    // "@/views/Adjustment/Surveydocument.vue"
                  ),
                meta: {
                  title1: "调证用户",
                  title: "调证报告",
                  showHeader: true,
                  showfooter: true,
                },
              },
            ],
          },
          {
            path: "/adjustment/querysuspects-Upload",
            name: "querysuspects-Upload",
            component: () =>
              import("@/views/newtheme/Adjustment/upload/Upload.vue"),
            // import("@/views/Adjustment/Upload.vue"),
            meta: {
              title1: "调证管理",
              title: "调证上传",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/adjustment/investigation",
            name: "investigation",
            component: () =>
              import("@/views/newtheme/Adjustment/investigation/index.vue"),
            meta: {
              title1: "调证管理",
              title: "调证协查",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/adjustment/documentfilet",
            name: "documentfile",
            component: () =>
              import(
                "@/views/newtheme/Adjustment/investigation/documentfile.vue"
                // "@/views/Adjustment/Surveydocument.vue"
              ),
            meta: {
              title1: "调证协查",
              title: "文书预览",
              showHeader: true,
              showfooter: true,
            },
          },
          // {
          //   path: "/adjustment/analysis",
          //   name: "adj-analysis",
          //   component: () => import("@/views/Adjustment/adj-Analysis.vue"),
          //   meta: {
          //     title1: "调证管理",
          //     title: "调证分析",
          //   },
          //   hidden: true,
          // },
        ],
      },

      //用户中心 和修改密码
      {
        path: "/personalinfo",
        name: "Personalinfo",
        component: () => import("@/components/Personalinfo"),
        meta: {
          title: "用户中心",
          showHeader: true,
          showfooter: true,
        },
      },
      {
        path: "/editpassword",
        name: "Editpassword",
        component: () => import("@/components/Editpassword"),
        meta: {
          title: "修改密码",
          showHeader: true,
          showfooter: true,
        },
      },
      {
        path: "/datacase",
        name: "Datacase",
        component: Datacase,
        meta: {
          title: "数据看板",
          showHeader: true,
          showfooter: true,
        },
        children: [
          // 产品功能介绍
          {
            path: "/datacase/researchanalysis",
            name: "researchanalysis",
            component: () => import("@/views/Analysis/AnalysisHome.vue"),
            meta: {
              title1: "数据看板",
              title: "大盘数据",
              showHeader: true,
              showfooter: false,
            },
          },
          {
            path: "/datacase/transactiondata",
            name: "transactiondata",
            // component: () => import("@/views/Datacase/newdatacase.vue"),
            component: () =>
              import("@/views/newtheme/newDatacase/caseData/datacasemain.vue"),
            meta: {
              title1: "数据看板",
              title: "交易数据",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/datacase/balancedata",
            name: "balancedata",
            component: () =>
              import(
                "@/views/newtheme/newDatacase/balanceData/balancedata.vue"
              ),
            // component: () => import("@/views/Datacase/balancedata.vue"),
            meta: {
              title1: "数据看板",
              title: "余额数据",
              showHeader: true,
              showfooter: true,
            },
          },
        ],
      },
      {
        path: "/help",
        name: "Help",
        component: Help,
        // redirect: "/help/Product-function",
        meta: {
          title: "帮助中心",
          showHeader: true,
          showfooter: true,
        },
        children: [
          // 产品功能介绍
          {
            path: "/help/Product-function",
            name: "Product-function",
            component: () =>
              import("@/views/newtheme/newHelp/production-fun.vue"),
            meta: {
              title1: "帮助中心",
              title: "产品功能介绍",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/help/National-involvment",
            name: "National-involvment",
            component: () =>
              import("@/views/newtheme/newHelp/National-involvment.vue"),
            meta: {
              title1: "帮助中心",
              title: "司法判例参考",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/help/prosecuteBook",
            name: "prosecuteBook",
            component: () =>
              import("@/views/newtheme/newHelp/prosecuteBook.vue"),
            meta: {
              title1: "起诉书",
              title: "司法判例参考",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/help/judgmentBook/:value",
            name: "judgmentBook",
            component: () =>
              import("@/views/newtheme/newHelp/judgmentBook.vue"),
            meta: {
              title1: "政策法规",
              title: "司法判例参考",
              showHeader: true,
              showfooter: true,
            },
          },

          {
            path: "/help/prosecutebookDocuments",
            name: "prosecutebookDocuments",
            component: () =>
              import("@/views/newtheme/newHelp/prosecutebookDocuments.vue"),
            meta: {
              title1: "帮助中心",
              title: "起诉书参考正文",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/help/exampledetails",
            name: "exampledetails",
            component: () =>
              import("@/views/newtheme/newHelp/ReferenceDocuments.vue"),
            meta: {
              title1: "帮助中心",
              title: "司法判例参考正文",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/help/judgmentBookDocuments/:value",
            name: "judgmentBookDocuments",
            component: () =>
              import("@/views/newtheme/newHelp/judgmentBookDocuments.vue"),
            meta: {
              title1: "帮助中心",
              title: "政策法规参考正文",
              showHeader: true,
              showfooter: true,
            },
          },
        ],
      },
      {
        path: "/permissions",
        name: "Permissions",
        component: Permissions,
        meta: {
          title: "权限管理",
          showHeader: true,
          showfooter: true,
        },
        children: [
          // 标签管理
          {
            path: "/permissions/labelList",
            name: "labelList",
            component: () =>
              import("@/views/newtheme/newlabellist/labellist.vue"),
            // import("@/views/Permissions/label/labelList.vue"),
            meta: {
              title1: "权限管理",
              title: "标签模板列表",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/permissions/userlist",
            name: "Userlist",
            component: () => import("../views/Permissions/UserList.vue"),
            meta: {
              title1: "权限管理",
              title: "用户列表",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/permissions/tloglist",
            name: "Tloglist",
            component: () =>
              import("../views/newtheme/newtloglist/Tloglist.vue"),
            meta: {
              title1: "权限管理",
              title: "用户日志",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/permissions/adminlog",
            name: "Adminlog",
            component: () =>
              import("../views/newtheme/newtloglist/Adminlog.vue"),
            meta: {
              title1: "权限管理",
              title: "管理日志",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/permissions/feelback",
            name: "Feelback",
            component: () => import("../views/newtheme/newfeelback/Feelback"),
            meta: {
              title1: "权限管理",
              title: "用户反馈",
              showHeader: true,
              showfooter: true,
            },
          },
          {
            path: "/permissions/roles",
            name: "Roles",
            component: () => import("../views/newtheme/newroles/Roleslist.vue"),
            meta: {
              title1: "权限管理",
              title: "角色列表",
              showHeader: true,
              showfooter: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   //没写就是任意路由都是定向这个
  //   path: "",
  //   //重定向
  //   redirect: "/home",
  // },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: Layout,
  // },
  {
    path: "/canvasshare/:param",
    name: "canvasshare",
    component: canvasshare,
  },
];

// mode:'hash',
const router = new VueRouter({
  // mode:'hash',
  routes,
});
/* 全局前置路由守卫 */
router.beforeEach((to, from, next) => {
  // 判断登录状态
  let isLogin = window.localStorage.getItem("t_k") ? true : false;

  // 登录过
  if (isLogin) {
    // console.log("我是路由");
    // console.log(to);
    // 新手引导以及画布操作
    // if (to.path == "/canvaslist/newcanvas") {
    //   let route;
    //   if (to.query.temporarilycanvas) {
    //     console.log("我是临时画布");
    //     route = "/canvaslist/newcanvas/temporarilycanvas";
    //   } else {
    //     console.log("我是正式画布");
    //     route = "/canvaslist/newcanvas";
    //   }
    //   getUserGuideData(route).then((res) => {
    //     console.log(res);
    //   });
    // }
    // 检查是否存在需要跳转的 URL
    // if (localStorage.redirectUrl) {
    //   const redirectUrl = JSON.parse(localStorage.redirectUrl);
    //   console.log("我是路由拦截器");
    //   console.log(redirectUrl);
    //   // 清除已存储的跳转 URL
    //   // localStorage.removeItem("redirectUrl");
    //   // 跳转到之前记录的页面
    //   next(redirectUrl);
    // } else {
    if (
      from.path === "/canvaslist/newcanvas" &&
      from.query.temporarilycanvas === "true" &&
      to.path !== "/canvaslist/newcanvas" &&
      to.query.temporarilycanvas !== "true"
    ) {
      // console.log("打印-----");
      // 弹出确认框
      Vue.prototype.$showDialog(
        "当前为临时画布，退出之后该画布将不存在，如需保留请保存画布。请问是否需要退出？",
        () => {
          // 用户点击了确认按钮，继续导航
          next();
        },
        () => {
          // 用户点击了取消按钮，取消导航
          // console.log(localStorage.canvastemporarily);
          next(false);
        }
      );
      // if (window.confirm("确定要离开临时画布页面吗？")) {
      //   // 用户点击确认，继续路由导航
      //   console.log(11);

      // } else {
      //   // 用户点击取消，阻止路由导航
      //   next(false);
      // }
    } else {
      next();
    }
    // next();

    // }
  } else {
    // 如果去的是登录
    if (to.path === "/login") {
      next(); // 直接放行
    } else if (to.path.startsWith("/canvasshare/")) {
      console.log("画布分享页面");
      next();
    }
    //  else if (to.path.startsWith("/canvas?temporarilycanvas=tru")) {
    //   console.log("画布分享页面");
    //   next();
    // }
    else if (to.path === "/forgetPassword") {
      next();
    } else if (to.path === "/register") {
      next();
    } else {
      next({
        path: "/login",
      }); // 跳转到登录
    }
  }
});

export default router;
// 跳转后自动返回页面顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});
