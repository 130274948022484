<script>
import { globalResource } from "@/aips/global";

let getConfigdata = [];
const getConfig = async () => {
  // console.log(process.env.VUE_APP_GC_URL);
  var newHostname = window.location.hostname;
  // console.log(newHostname);
  var data = {};
  if (
    newHostname == "dashboard.tanlian.online" ||
    newHostname == "api.tanlian.online"
  ) {
    data = {
      site: "TANLIAN",
    };
  } else if (newHostname == "dashboard.graphchain.top") {
    data = {
      site: "GRAPHCHAIN",
    };
  } else {
    data = {
      site: "GRAPHCHAIN",
    };
  }
  const res = await globalResource(data);
  console.log(res);
  if (getConfigdata.length <= 0) {
    getConfigdata.push(res.data[0]);
  } else {
    getConfigdata.splice(0, 1, res.data[0]);
  }
};

export default {
  getConfig,
  getConfigdata,
};
</script>
