<template>
  <div class="line-chart">
    <div class="line-title">
      <!-- <img src="../../assets/image/title.png" alt="" /> -->
      <span>恶意地址转账趋势图</span>
    </div>
    <div id="main1"></div>
    <!-- <a href="../assets/json/map/china.json"></a> -->
  </div>
</template>

<script>
// import china from '../assets/json/map/china.json'
import * as echarts from 'echarts'

export default {
  props: ["addressTransfer"],
  data() {
    return {
      statistics: "",
      xdate: "",
      MaxNum: 100,
      timer: null,
    };
  },
  watch: {
    addressTransfer: {
      handler(newVal) {
        //console.log(newVal);
        //console.log("恶意地址----我是子组件的watch监听");
        this.addressTransfer = newVal;
        this.MathValue();
      },
      // 开启深度侦听
      deep: true,
      // 立即监听
      immediate: true,
    },
  },
  created() {},
  methods: {
    MathValue() {
      var MaxArr = [];
      this.addressTransfer.statistics.forEach((item) => {
        item.totalAmountList.forEach((maxItem) => {
          MaxArr.push(maxItem);
        });
      });
      // if (this.MaxArr <= 1000) {
      //   this.MaxArr = 1000;
      // }

      //console.log("我是恶意地址最大的值");
      //console.log(this.MaxNum);
      var maxAddress = Math.max(...MaxArr).toString();
      var strLength = maxAddress.length;
      var maxNum = Number(maxAddress[0]) + 1;
      var firstNum;
      var maxlast;
      if (maxNum >= 10) {
        const lengthLast = strLength + 1;
        firstNum = maxNum.toString();
        maxlast = firstNum.padEnd(lengthLast, "0");
      } else {
        firstNum = maxNum.toString();
        maxlast = firstNum.padEnd(strLength, "0");
      }
      this.MaxNum = maxlast;
    },
    chartfn(statistics, xdate) {
      //console.log("我是图表里面的内容");
      var mCharts = echarts.init(document.getElementById("main1"));
      var series1 = [];
      if (statistics) {
        statistics.map(item=>{
          console.log(item);
          if(item.txType=='四方支付交易'){
            item.color='rgba(53, 122, 255, 0.3)'
            item.linecolor='rgba(53, 122, 255, 1)'
          }
          if(item.txType=='涉赌交易'){
            item.color='rgba(232, 143, 71, 0.3)'
            item.linecolor='rgba(232, 143, 71, 1)'
          }
          series1.push({
            name: item.txType,
            data: item.totalAmountList,
            type: "line",
            areaStyle: {
              color:item.color
            },
            
lineStyle:{
  color:item.linecolor,

},
itemStyle:{
  color:item.linecolor
},
            smooth: true,
          })
        })
       
      }

      //console.log("我是series");
      //console.log(series1);
      let option = {
        title: {
          show: false,
        },
        // 鼠标进入之后显示数据
        tooltip: {
          show: true,
          confine: true,
          backgroundColor: "#8191C2",
          padding: [20, 10],
          textStyle: {
            color: "#fff",
          },
          extraCssText: "box-shadow:0px 0px 11px 0px #66FFFF",
          // 可以自己定义鼠标进入之后显示的数据
          formatter: (obj) => {
            return [obj.seriesName, obj.data + "万"].join("<br>");
          },
        },
        // legend: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdate,
          // boundaryGap: 0,
          // scale: true,
        },
        yAxis: {
          type: "value",
          scale: true,
          name: "(万元)",
          nameTextStyle: {
            color: "#8692a5", // 设置单位文字的颜色
            // fontSize: 12, // 设置单位文字的字体大小
            // fontStyle: "italic", // 设置单位文字的字体样式
            // fontWeight: "bold", // 设置单位文字的字体粗细
            // 其他样式配置项...
          },
          max: this.MaxNum,

          min: 0,
          splitNumber: 10,
          splitLine: {
            //  修改横线的样式
            lineStyle: {
              color: "#546079",
              width: 0.5,
            },
          },
          axisLabel: {
            color: "#8692a5", // 设置刻度数字的颜色
          },
          // boundaryGap: [0.2, 0.2],
        },
        grid: {
          top: 120,
          x: 70,
          y: 0,
          x2: 30,
          y2: 20,
        },
        legend: {
          icon: "rect",
          left: "20",
          top: "40",
          bottom: "5",
          // itemGap: 50,
          // height: "10px",
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: "#8692a5",
            fontSize: "16px",
            padding: [0, 10, 20, 6],
            fontWeight: 400,
            lineHeight: 20,
            overflow: "break",
          },
        },

        series: series1,
      };
      mCharts.setOption(option);
      // 监听浏览器变化,图片对象调用缩放resize函数
      window.addEventListener("resize", function () {
        mCharts.resize();
      });
    },
    //地图点击
    // clickfn(mCharts, chinajson) {
    //   mCharts.on("click", (e) => {
    //     //console.log(e);
    //     chinajson.features.forEach((item) => {
    //       if (e.name === item.properties.name) {
    //         //console.log(item);
    //       }
    //     });
    //   });
    // },
  },
  created() {},
  mounted() {
    this.timer = setTimeout(() => {
      this.statistics = this.addressTransfer.statistics;
      this.xdate = this.addressTransfer.xdate;
      this.chartfn(this.statistics, this.xdate);
    }, 1500);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>
<style lang="less" scoped>
.line-chart {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  .line-title {
    width: 100%;
    box-sizing: border-box;
    // height: 6%;
    padding:0 20px;
    // img {
    //   width: 18px;
    //   height: 18px;
    // }

    span:nth-child(1) {
      color: var(--web-color-text-primary, #F2F4F6);
font-family: "PingFang SC";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
      // height:200px
    }
  }
  #main1 {
    width: 100%;
    height: 92%;
    // background-color: aquamarine;
  }
}
</style>
