<template>
  <div style="width: 100%" class="binding-guge">
    <div class="textConAny">
      <p class="bind-p1">为了您的账户安全，请先绑定谷歌身份验证再登录</p>
      <!-- <p class="bind-p2">Google 身份验证器绑定方式</p> -->
      <p class="bind-p3">1、微信小程序绑定</p>
      <div class="b-binding">
        <div class="binding-min">
          <div class="binding-img">
            <img
              class="cx-img"
              src="@/assets/image/login/google1.png"
              alt="小程序图片"
            />
            <img src="@/assets/image/login/google2.png" alt="小程序图片" />
          </div>
          <p class="step">
            第一步：在微信搜索“腾讯身份验证器”或者“宁盾令牌”进入小程序
          </p>
        </div>
        <div class="binding-min">
          <div class="google-img">
            <p>Google验证二维码扫码绑定</p>
            <img :src="newSrc" alt="二维码" />
            <p>Google验证激活码令牌（在小程序手动输入绑定）</p>
            <p>{{ googleform.secretKey }}</p>
          </div>
          <p class="step">
            第二步：打开小程序扫上方Google验证二维码绑定
            或者手动输入Google验证激活码进行绑定
          </p>
        </div>
        <div class="binding-min1">
          <img src="@/assets/image/login/google3.png" alt="小程序图片" />
          <p class="step">第三步：请将小程序中动态数字输入下方输入框进行绑定</p>
        </div>
      </div>
      <p class="bind-p3" style="margin-top: 30px">
        2、手机下载安装宁盾令牌（iOS和Android手机用户）或者google
        authenticator绑定（仅限iOS手机用户）
      </p>
      <div class="b-binding">
        <div class="binding-min">
          <div class="binding-img">
            <img
              class="cx-img"
              src="@/assets/image/login/google4.png"
              alt="小程序图片"
            />
            <img src="@/assets/image/login/google5.png" alt="小程序图片" />
          </div>
          <p class="step">
            第一步：在App Store或者安卓应用市场搜索对应app然后下载
          </p>
        </div>
        <div class="binding-min">
          <div class="google-img">
            <p>Google验证二维码：</p>
            <img :src="newSrc" alt="二维码" />
            <p>Google验证激活码令牌：</p>
            <p>{{ googleform.secretKey }}</p>
          </div>
          <p class="step">
            第二步：打开google
            authenticator，扫上方Google验证二维码绑定或者手动输入Google验证激活码进行绑定
          </p>
        </div>
        <div class="binding-min1">
          <img src="@/assets/image/login/google6.png" alt="小程序图片" />
          <p class="step">
            第三步：请将 authenticator中动态数字输入下方输入框进行绑定
          </p>
        </div>
      </div>
      <div class="code" v-if="googleform.bindingFlag == 0">
        <div class="ver-code">验证码</div>
        <div
          class="becurrentinput"
          style="display: flex; justify-content: start; margin-bottom: 20px"
        >
          <el-input
            placeholder="请输入动态验证码"
            v-model="verCode"
            clearable
          ></el-input>
          <div class="btnbox">
            <el-button type="success" @click="sureverCode">确认</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GoogleQr, bindingverCode } from "@/aips/login";
export default {
  props: ["googleform", "handleClose1"],
  data() {
    return {
      newSrc: "",
      verCode: null,
      // secretKey: null,
    };
  },
  created() {
    this.getQRcode();
  },
  watch: {
    googleform: {
      handler(newval) {
        console.log(newval);
        this.googleform = newval;
        this.getQRcode();
      },
      // 开启深度侦听
      deep: true,
      immediate: true,
    },
    // secretKey: {
    //   handler(newval) {
    //     console.log(newval);
    //     this.secretKey = newval;
    //   },
    //   // 开启深度侦听
    //   deep: true,
    // },
  },
  methods: {
    getQRcode() {
      console.log(this.googleform);
      const name = this.googleform.name;
      const secretKey = this.googleform.secretKey;
      //console.log(name);
      console.log(secretKey);
      GoogleQr(name, secretKey).then((res) => {
        this.newSrc = res.config.baseURL + "/" + res.config.url;
        console.log(this.newSrc);
      });
    },
    sureverCode() {
      console.log("确认登录");
      console.log(localStorage.token);
      let token1 = JSON.parse(localStorage.token);
      let time1 = Date.now();
      console.log(token1.expireTime);
      console.log(time1);
      if (time1 >= token1.expireTime) {
        // location.reload();
        this.$message("验证时间过长，请刷新页面重新登录！");
        this.$emit("handleClose1");
        localStorage.clear();
        return;
      }
      // console.log("没有超时");
      // console.log(this.verCode);
      // console.log(this.googleform.userId);
      // console.log(this.googleform.secretKey);
      bindingverCode(this.verCode, this.googleform.secretKey).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$emit("handleClose1");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/newlogin.less";
.forgetpassword-style {
  cursor: pointer;
}
</style>
