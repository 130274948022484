<template>
  <div>
    <div class="homeview-content" v-if="ishome">
      <div class="homeview-main">
        <Homemain @goanalysis="goanalysis"></Homemain>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Homemain from "@/views/newtheme/newhome/homemain.vue";

export default {
  components: {
    Homemain,
  },
  data() {
    return { ishome: true };
  },
  watch: {
    "$route.path"(toPath, fromPath) {
      if (toPath == "/home") {
        this.ishome = true;
      } else {
        this.ishome = false;
      }
    },
  },
  methods: {
    goanalysis(data) {
      this.ishome = false;
      console.log("打印一下我这个data");
      console.log(data);
      this.$router.push({
        // path: "/home/addressanalysis",
        path: `/home/addressdetail/${data.address}`,
        query: { data: JSON.stringify(data) },
        // query: { id: id },
      });
    },
  },
  created() {
    if (this.$route.path == "/home") {
      this.ishome = true;
    } else {
      this.ishome = false;
    }
  },
};
</script>
<style lang="less">
.homeview-main {
  min-height: 95vh;
  background: #091121 url(@/assets/image/homepage/banner.png) no-repeat;
  background-size: 100%;
}
</style>
